import { Building } from '@/interface/building';
import { DocBreaker } from '@/interface/docTypes';
import { Circuit, PanelDetailType } from './panelTypes';

export enum DataType {
  Building = 'building',
  Panel = 'panel',
  SubPanel = 'subPanel',
  Breaker = 'breaker',
  SMCBreaker = 'smcBreaker',
}
export enum NodeType {
  Building = 'buildingNode',
  Panel = 'panelNode',
  Breaker = 'breakerNode',
}
export type HierarchyNode = {
  id: string;
  name: string;
  label?: string;
  type: DataType;
  siblingIsSMC?: boolean;
  parentBreakerId?: string;
  children: HierarchyNode[];
  mainBreakerId?: string;
  endUseId?: number;
  circuits?: Circuit[];
  originData?: Building | PanelDetailType | DocBreaker;
};
